
.main-carousel__body {
    position: relative;
    width: 100%;
    left: 0;
    padding: 0.5rem 1rem;
    display: flex;
    background: black;
    align-items: center;
    justify-content: space-between;
    color: black;
}

.image {
    width: 80vw;
    height: 80vh;
    border-radius: 10px;
    background-size: cover;
    margin-top: 0;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}


.slide {
    
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

@media (max-width: 40rem) {

    .image {
        width: auto ;
  max-width: 100% ;
  height: auto ;
    }

.main-carousel__body {
    
    height: 60%;

}

}

