@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lobster&family=Roboto+Mono:wght@100;300&display=swap');


.main__home {
    border: 1px solid black;
    text-align: center;
    height: vh;
    width: vw;
    display: flex;
    margin-top: 2rem;

}

.main__home_image__container {
    display: flex;
    width: 40%;
    height: vh;
    padding: 2rem;

}

.main__home_image {
    width: 100%;
    height: 35rem;
}

.main__home_info {
    display: flex;
    flex-direction: column;
    width: 59%;
    background-color: black;
    height: 35rem;
    align-items: center;
    justify-content: center;
}

.main-nav__title {
    font-family: 'Great Vibes', cursive;
    font-size: 4rem;
    color: red;

    font-family: 'Lobster', cursive;
    font-family: 'Montserrat', sans-serif;
}

.span1 {
    color: red;
}

.span2 {
    color: yellow;
}

.main__home_info__title {

    padding: 3rem;
    color: yellow;
    font-size: 3rem;
    text-align: left;
    font-family: 'Great Vibes', cursive;
    font-family: 'Lobster', cursive;
    font-family: 'Roboto Mono', monospace;
}

@media (max-width: 40rem) {
    .main__home {
        
        display: flex;
        flex-direction: column;
       
    
    }
    .main__home_image__container {
        display: flex;
        width: 90%;
    
    }


.main__home_info {
    display: flex;
    flex-direction: column;
    width: 100%;
}
  }