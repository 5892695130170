
.main-footer {
    background: black;
    padding: 2rem;
    margin-top: 3rem;
      z-index: 1;
  }
  
  .main-footer__links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .main-footer__link {
    margin: 0.5rem 0;
  }
  
  .main-footer__link a {
    color: white;
    text-decoration: none;
  }
  
  .main-footer__link a:hover,
  .main-footer__link a:active {
    color: #ccc;
  }
  

@media (max-width: 40rem) {
    .main-footer__link {
      margin: 0 1rem;
    }
  
    .main-footer__links {
      flex-direction: column;
      justify-content: center;
    }
  }