@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,900');
@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

* {
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.main {
  min-height: calc(100vh - 3.5rem - 8rem);
  margin-top: 3.5rem;
}

.backdrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.main-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #2ddf5c;
  padding: 0.5rem 1rem;
  z-index: 1;
}

.main-header > div {
  display: inline-block;
  vertical-align: middle;
}

.toggle-button {
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__bar {
  width: 100%;
  height: 0.2rem;
  background: black;
  display: block;
  margin: 0.6rem 0;
}

.main-header__brand {
  color: #0e4f1f;
  text-decoration: none;
  font-weight: bold;
  height: 2.5rem;
  /* width: 20px; */
  display: inline-block;
  vertical-align: middle;
}

.main-header__brand img {
  height: 100%;
  /* width: 100%; */
}

.main-nav {
  display: none;
}

.main-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav__item {
  display: inline-block;
  margin: 0 1rem;
}

.main-nav__item a,
.mobile-nav__item a {
  text-decoration: none;
  color: #0e4f1f;
  font-weight: bold;
  padding: 0.2rem 0;
}

.main-nav__item a:hover,
.main-nav__item a:active {
  color: white;
  border-bottom: 5px solid white;
}

.main-nav__item--cta a,
.mobile-nav__item--cta a {
  color: white;
  background: #ff1b68;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.main-nav__item--cta a:hover,
.main-nav__item--cta a:active,
.mobile-nav__item--cta a:hover,
.mobile-nav__item--cta a:active {
  color: #ff1b68;
  background: white;
  border: none;
}

@media (min-width: 40rem) {
  .toggle-button {
    display: none;
  }

  .main-nav {
    display: inline-block;
    text-align: right;
    width: calc(100% - 44px);
    vertical-align: middle;
  }
}

.main-footer {
  background: black;
  padding: 2rem;
  margin-top: 3rem;
}

.main-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.main-footer__link {
  display: block;
  margin: 1rem 0;
}

.main-footer__link a {
  color: white;
  text-decoration: none;
}

.main-footer__link a:hover,
.main-footer__link a:active {
  color: #ccc;
}

.mobile-nav {
  display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background: white;
  width: 80%;
  height: 100vh;
}

.mobile-nav__items {
  width: 90%;
  height: 100%;
  list-style: none;
  margin: 10% auto;
  padding: 0;
  text-align: center;
}

.mobile-nav__item {
  margin: 1rem 0;
}

.mobile-nav__item a {
  font-size: 1.5rem;
}

.button {
  background: #0e4f1f;
  color: white;
  font: inherit;
  border: 1.5px solid #0e4f1f;
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: white;
  color: #0e4f1f;
}

.button:focus {
  outline: none;
}

.button[disabled] {
  cursor: not-allowed;
  border: #a1a1a1;
  background: #ccc;
  color: #a1a1a1;
} 

.open {
    display: block !important;
}

@media (min-width: 40rem) {
  .main-footer__link {
    display: inline-block;
    margin: 0 1rem;
  }
}
